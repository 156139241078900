var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lz-download" }, [
    _c(
      "div",
      {
        staticClass: "lz-download-view",
        on: { click: _vm.handleDownloadClick }
      },
      [
        _vm.customTrigger
          ? _vm._t("trigger")
          : _c(
              "div",
              { class: ["lz-download-button", { disabled: _vm.loading }] },
              [
                !_vm.loading
                  ? _c("i", { staticClass: "iconfont lzicon-export_outline" })
                  : _c("i", { staticClass: "iconfont lzicon-loading" }),
                _c("span", { staticClass: "trigger-title" }, [
                  _vm._v(_vm._s(_vm.triggerTitle))
                ])
              ]
            )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }