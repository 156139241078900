var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-cascader", {
    ref: "cityCascader",
    style: {
      width: typeof _vm.width == "string" ? _vm.width : _vm.width + "px"
    },
    attrs: {
      props: _vm.childProps,
      options: _vm.resource,
      size: "small",
      disabled: _vm.disabled,
      placeholder: _vm.placeholder,
      filterable: _vm.filterable,
      clearable: _vm.clearable,
      "collapse-tags": _vm.collapseTag,
      "show-all-levels": _vm.showAllLevels
    },
    on: { change: _vm.handleChange },
    model: {
      value: _vm.value,
      callback: function($$v) {
        _vm.value = $$v
      },
      expression: "value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }