<template>
  <div class="lz-download">
    <div class="lz-download-view" @click="handleDownloadClick">
      <slot name="trigger" v-if="customTrigger"></slot>
      <div v-else :class="['lz-download-button', { disabled: loading }]" >
        <i v-if="!loading" class="iconfont lzicon-export_outline"></i>
        <i v-else class="iconfont lzicon-loading"></i>
        <span class="trigger-title">{{ triggerTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import downloadHelper from './file-download.js'

/**
 * File download
 */
export default {
  name: 'LzFileDownload',
  props: {
    // 触发器标题
    triggerTitle: {
      type: String,
      default: '下载'
    },

    // 下载接口 Host
    apiHost: {
      type: String,
      required: true
    },

    // 下载接口 path
    apiPath: {
      type: String,
      required: true
    },

    // 下载接口 http method
    httpMethod: {
      type: String,
      default: 'GET'
    },

    // 下载接口参数
    httpData: {
      type: Object
    },

    // 下载文件名
    fileName: {
      type: String
    }
  },
  data () {
    return {
      loading: false,

      // 是否自定义 trigger
      customTrigger: false
    }
  },
  mounted () {
    this.customTrigger = this.$slots.trigger !== undefined;
  },
  methods: {
    /**
     * 下载点击事件
     */
    handleDownloadClick () {
      if (!this.loading) {
        this.downloadFile();
      }
    },

    /**
     * 下载文件
     */
    downloadFile () {
      this.loading = true;
      downloadHelper.downloadFile(this.apiHost, this.apiPath, this.httpMethod, this.httpData, this.fileName).then(res => {
        this.loading = false;
        this.$emit('success');
      }).catch(error => {
        this.loading = false;
        this.$notice.error(error.message);
        this.$emit('fail');
      });
    },
  }
}
</script>

<style scoped>
  .lz-download {
    display: inline-block;
    vertical-align: middle;
  }

  .lz-download .lz-download-button {
    height: 32px;
    text-align: center;
    color: #497CF6;
    line-height: 1;
    border-radius: 2px;
    border: 1px solid #497CF6;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }

  .lz-download .lz-download-button i {
    font-size: 14px;
    margin-right: 4px;
    line-height: 30px;
    vertical-align: middle;
    display: inline-block;
  }

  .lz-download .lz-download-button .lzicon-loading {
    animation: loading-loop 1s linear infinite;
  }

  @keyframes loading-loop {
    from {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .lz-download .lz-download-button .trigger-title {
    line-height: 30px;
    display: inline-block;
    vertical-align: middle;
  }

  .lz-download .lz-download-button.disabled {
    color: #c5c8ce;
    background-color: #f7f7f7;
    border-color: #DEE3EE;
    cursor: not-allowed;
  }
</style>
