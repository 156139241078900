<template>
  <div class="page-box container-box">
    <div v-if="isPageViewable" class="page-panel">
      <div ref="listBox" class="data-list">
        <div ref="listHead" class="data-list__header">
          <el-form class="lz-form--inline" :model="filterForm" label-width="73px">
            <el-row :gutter="16">
              <el-col :sm="10" :md="8" :lg="8" :xl="6">
                <el-form-item label="品牌酒店：">
                  <lz-cascader-city
                    :resource="brandHotelList"
                    :childProps="cascaderProps"
                    :show-all-levels="false"
                    placeholder="选择品牌及考勤酒店"
                    width="100%"
                    @lzCascaderChange="handleCascaderCityChange">
                  </lz-cascader-city>
                </el-form-item>
              </el-col>
              <el-col :sm="10" :md="8" :lg="6" :xl="4">
                <el-form-item label="日期：">
                  <el-date-picker
                    v-model="filterForm.date"
                    type="month"
                    :picker-options="datePickerOptions"
                    :clearable="false"
                    placeholder="选择考勤月份">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :sm="4" :md="8" :lg="6" :xl="4">
                <el-form-item label-width="0">
                  <el-button type="primary" class="action-button" @click="handleQueryClick">查询</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <!-- 分隔线视图 -->
          <div class="data-list__separator"></div>
        </div>

        <div class="data-list__body">
          <div ref="listAction" class="data-list__action display__flex justify_content__space_between align_items__center">
            <color-lump-tips :datas="abnormalMarkEnum"></color-lump-tips>
            <div v-if="isExportPermission">
              <lz-file-download
                trigger-title="导出"
                :api-host="fileDownloadAPIHost"
                api-path="/hrms/attendance/exportAttendance"
                http-method="POST"
                :http-data="processFormData(filterForm, false)">
              </lz-file-download>
            </div>
          </div>
          <div class="data-list__table">
            <el-table
              :empty-text="noDataText"
              :data="tableDatas"
              :max-height="tableMaxHeight"
              :row-style="getRowStyle"
              :cell-style="getCellStyle"
              border
              :span-method="getSpanData"
              v-loading="tableLoading">
              <el-table-column prop="brandName" label="品牌" align="center" fixed min-width="110"></el-table-column>
              <el-table-column prop="hotelName" label="考勤酒店" align="center" fixed min-width="150"></el-table-column>
              <el-table-column prop="hotelVid" label="酒店编码" align="center" fixed min-width="80"></el-table-column>
              <el-table-column prop="hotelCode" label="酒店代码" align="center" fixed min-width="80"></el-table-column>
              <el-table-column label="员工" align="center" fixed min-width="100">
                <template slot-scope="scope">
                  <span v-if="!scope.row.remark">{{ scope.row.userName }}</span>
                  <el-popover
                    v-else
                    trigger="hover">
                    <div slot="reference">
                      <span style="vertical-align: middle;">{{ scope.row.userName }}</span>
                      <lz-icon
                        class="cell-tips__icon"
                        type="iconfont"
                        name="lzicon-warning"
                        :size="14">
                      </lz-icon>
                    </div>
                    <div style="max-width: 300px; white-space: normal;">{{ scope.row.remark }}</div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column prop="userHotelName" label="员工所属酒店" align="center" fixed min-width="150"></el-table-column>
              <el-table-column prop="jobName" label="职位" align="center" min-width="100"></el-table-column>
              <template>
                <el-table-column
                  v-for="index in filterDays"
                  :key="index"
                  :prop="'date_'+index"
                  :label="formatHeaderDateColumn(index)"
                  align="center"
                  min-width="80">
                  <template slot-scope="scope">
                    <div v-if="checkDateColumnData(scope.row, index)">
                      {{ getDateAttendanceDesc(scope.row.id, index) }}
                    </div>
                  </template>
                </el-table-column>
              </template>
              <el-table-column prop="foreShiftCount" label="早班" align="center" min-width="80"></el-table-column>
              <el-table-column prop="middleShiftCount" label="中班" align="center" min-width="80"></el-table-column>
              <el-table-column prop="nightShiftCount" label="晚班" align="center" min-width="80"></el-table-column>
              <el-table-column prop="administrativeShiftCount" label="行政班" align="center" min-width="80"></el-table-column>
              <el-table-column prop="restCount" label="休息" align="center" min-width="80"></el-table-column>
              <el-table-column prop="lateCount" label="迟到" align="center" min-width="80"></el-table-column>
              <el-table-column prop="leaveEarlyCount" label="早退" align="center" min-width="80"></el-table-column>
              <el-table-column prop="absentCount" label="旷工" align="center" min-width="80"></el-table-column>
              <el-table-column prop="askOffCount" label="请假" align="center" min-width="80"></el-table-column>
              <el-table-column prop="oweDays" label="上月欠休" align="center" min-width="80"></el-table-column>
              <el-table-column prop="notWorkCount" label="缺勤数" align="center" min-width="80"></el-table-column>
              <el-table-column prop="workCount" label="出勤数" align="center" min-width="80"></el-table-column>
              <el-table-column label="提交人" align="center" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.operator | default_chars }}</span>
                </template>
              </el-table-column>
              <el-table-column label="提交时间" align="center" min-width="135">
                <template slot-scope="scope">
                  {{ scope.row.submitTime | default_chars }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div ref="listFoot" class="data-list__footer">
          <lz-pagination
            :total="pageData.total"
            :page-count="pageData.pageCount"
            :page-size="pageData.pageSize"
            :current-page="pageData.currentPage"
            @on-change="handlePaginationOnChange">
          </lz-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import config from '@/config'
import LzCascaderCity from '_c/cascader-city'
import LzFileDownload from '_c/file-download'
import dateHelper from '@/libs/datetime'
import filterTableMixin from '@/common/mixin/filter-table.js'
import listMixin from '../mixins/list'
import serviceAPI from '../api'
import ColorLumpTips from '../schedule/color-lump.vue'

/**
 * 考勤管理 - 集团考勤
 * @module @/view/attendance
 */
export default {
  name: 'GroupAttendance',
  mixins: [ filterTableMixin, listMixin ],
  components: {
    LzCascaderCity,
    LzFileDownload,
    ColorLumpTips
  },
  data () {
    return {
      filterForm: {
        hotelVids: [],
        date: ''
      },

      tableLoading: false,

      // 分页
      pageData: {
        pageSize: 20
      },

      // 考勤有效数据缓存 { id: { day: {}}}
      dailyDatas: null,

      brandHotelList: [],
      cascaderProps: {
        multiple: true,
        value: 'hotelVid',
        label: 'hotelName',
        children: 'hotels'
      },
      datePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    }
  },
  computed: {
    ...mapState('attendance', [
      'abnormalMarkEnum'
    ]),

    ...mapGetters([
      'pageButtonPermission'
    ]),

    ...mapGetters('attendance', [
      'attendanceAbnormalMark',
      'isAttendanceAbnormal'
    ]),

    // 是否具有“集团考勤查看”权限
    isPageViewPermission () {
      return this.pageButtonPermission('group_attendance', 'attendanceViewButton');
    },

    // 是否具有“集团考勤导出”权限
    isExportPermission () {
      return this.pageButtonPermission('group_attendance', 'attendanceExportButton');
    },

    // 是否“集团考勤”可查看（拥有view、export任一权限）
    isPageViewable () {
      return this.isPageViewPermission || this.isExportPermission;
    },

    // 导出接口 HOST
    fileDownloadAPIHost () {
      return config.serverConfig.serviceURL;
    }
  },
  created () {
    this.initData();
  },
  methods: {
    ...mapActions('attendance', [
      'getAttendanceStateSelectList'
    ]),

    initData () {
      if (this.isPageViewable) {
        this.filterForm.date = this.dateNow;
        this.setFilterDays(this.dateNow);

        this.getAttendanceStateSelectList();
        this.getBrandHotelList();
        this.getAttendanceListPage();
      }
    },

    /**
     * Table row style
     */
    getRowStyle ({ row, rowIndex }) {
      if (row.id === -1) {
        // “合计”行
        return {'font-weight': 'bold'};
      }
    },

    /**
     * Table 单元格 style
     */
    getCellStyle ({row, column, rowIndex, columnIndex}) {
      return this.getCellStyleWithRefer(row, column, 2);
    },

    /**
     * Table span method
     */
    getSpanData ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === this.tableDatas.length-1) {
        // “合计”行
        if (columnIndex === 0) {
          return [1, 6];
        } else if (columnIndex>0 && columnIndex<6) {
          return [0, 0];
        } else if (columnIndex === 6) {
          return [1, this.filterDays+1];
        } else if (columnIndex>6 && columnIndex<this.filterDays+1+6) {
          return [0, 0];
        }
      }
    },

    /**
     * “品牌酒店”选择事件
     */
    handleCascaderCityChange (data) {
      let list = [];
      data.forEach(item => {
        if (item.length > 0) {
          list.push(item[item.length - 1]);
        }
      });
      this.filterForm.hotelVids = list;
    },


    /**
     * “查询”点击事件
     */
    handleQueryClick () {
      this.handlePaginationOnChange(1);
    },

    /**
     * 分页点击事件
     * @param { Number } curNum 当前页
     */
    handlePaginationOnChange (curNum) {
      this.pageData.currentPage = curNum;
      this.getAttendanceListPage();
    },


    /**
     * 获取品牌酒店列表
     */
    getBrandHotelList () {
      serviceAPI.brandHotelList().then(res => {
        if (res.code==200 && res.data) {
          this.processBrandHotel(res.data);
        }
      }).catch(error => {
        console.error('Get brand hotel list error: ', error.message);
      });
    },

    /**
     * 处理品牌酒店数据
     */
    processBrandHotel (data) {
      data.forEach(item => {
        item.hotelVid = item.brandCode;
        item.hotelName = item.brandName;
      });
      this.brandHotelList = [{
        hotelVid: 0,
        hotelName: '全部酒店',
        hotels: data
      }]
    },

    /**
     * 获取集团考勤分页列表
     */
    getAttendanceListPage () {
      let params = this.processFormData(this.filterForm);
      this.tableLoading = true;
      serviceAPI.attendanceListPage(params).then(res => {
        this.tableLoading = false;
        if (res.code==200 && res.data && res.data.datas) {
          this.pageData.total = res.data.total;
          this.pageData.pageCount = res.data.totalPage;
          let tableDatas = res.data.datas[0].list;
          let summaryData = res.data.datas[0].total;
          this.tableDatas = tableDatas;
          if (tableDatas.length) {
            // “合计”数据添加到 table 最后一行
            summaryData.id = -1;
            summaryData.brandName = '合计';
            summaryData.details = [];
            this.tableDatas.push(summaryData);
          }
        }
        this.setTableMaxHeight(0, 'listBox', 'listHead', 'listAction', 'listFoot');
        this.setFilterDays(this.filterForm.date);
      }).catch(error => {
        this.tableLoading = false;
        this.setFilterDays(this.filterForm.date);
        console.error('Get group attendance list page error: ', error.message);
      });
    },
    
    /**
     * 构造筛选表单数据
     * @param { Object } 原始数据对象
     * @param { Boolean } isPage 是否是分页列表查询
     * @returns { Object } 查询表单数据对象
     */
    processFormData (data, isPage=true) {
      let form = {
        hotelVids: data.hotelVids,
        workShiftDate: dateHelper.format(data.date, 'yyyy-MM')
      }
      if (isPage) {
        form.pageIndex = this.pageData.currentPage;
        form.pageSize = this.pageData.pageSize;
      }
      return form;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/const";

  .container-box {
    padding: 20px;
  }

  .page-panel {
    height: 100%;
    padding: 16px;
    border-radius: 2px;
    background-color: #FFF;
  }

  .data-list {
    height: 100%;
    overflow: hidden;
  }

  .data-list__header {
    .data-list__separator {
      margin-top: 4px;
      height: 1px;
      background-color: $dividing-line-color;
    }
  }

  .data-list__body {
    .data-list__action {
      text-align: right;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .data-list__table {
    ::v-deep .el-table__fixed {
      pointer-events: none;

      th, td {
        pointer-events: auto;
      }
    }

    ::v-deep .cell {
      line-height: 1.2;
    }

    .cell-tips__icon {
      color: $text-color;
      opacity: 0.6;
      line-height: 1;
      transform: rotate(180deg);
      vertical-align: middle;
      margin-left: 3px;
    }
  }

  .data-list__footer {
    padding-top: 12px;
  }
</style>
