var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-box container-box" }, [
    _vm.isPageViewable
      ? _c("div", { staticClass: "page-panel" }, [
          _c("div", { ref: "listBox", staticClass: "data-list" }, [
            _c(
              "div",
              { ref: "listHead", staticClass: "data-list__header" },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "lz-form--inline",
                    attrs: { model: _vm.filterForm, "label-width": "73px" }
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 16 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { sm: 10, md: 8, lg: 8, xl: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "品牌酒店：" } },
                              [
                                _c("lz-cascader-city", {
                                  attrs: {
                                    resource: _vm.brandHotelList,
                                    childProps: _vm.cascaderProps,
                                    "show-all-levels": false,
                                    placeholder: "选择品牌及考勤酒店",
                                    width: "100%"
                                  },
                                  on: {
                                    lzCascaderChange:
                                      _vm.handleCascaderCityChange
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 10, md: 8, lg: 6, xl: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "日期：" } },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "month",
                                    "picker-options": _vm.datePickerOptions,
                                    clearable: false,
                                    placeholder: "选择考勤月份"
                                  },
                                  model: {
                                    value: _vm.filterForm.date,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filterForm, "date", $$v)
                                    },
                                    expression: "filterForm.date"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 4, md: 8, lg: 6, xl: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "action-button",
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleQueryClick }
                                  },
                                  [_vm._v("查询")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "data-list__separator" })
              ],
              1
            ),
            _c("div", { staticClass: "data-list__body" }, [
              _c(
                "div",
                {
                  ref: "listAction",
                  staticClass:
                    "data-list__action display__flex justify_content__space_between align_items__center"
                },
                [
                  _c("color-lump-tips", {
                    attrs: { datas: _vm.abnormalMarkEnum }
                  }),
                  _vm.isExportPermission
                    ? _c(
                        "div",
                        [
                          _c("lz-file-download", {
                            attrs: {
                              "trigger-title": "导出",
                              "api-host": _vm.fileDownloadAPIHost,
                              "api-path": "/hrms/attendance/exportAttendance",
                              "http-method": "POST",
                              "http-data": _vm.processFormData(
                                _vm.filterForm,
                                false
                              )
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "data-list__table" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading"
                        }
                      ],
                      attrs: {
                        "empty-text": _vm.noDataText,
                        data: _vm.tableDatas,
                        "max-height": _vm.tableMaxHeight,
                        "row-style": _vm.getRowStyle,
                        "cell-style": _vm.getCellStyle,
                        border: "",
                        "span-method": _vm.getSpanData
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "brandName",
                          label: "品牌",
                          align: "center",
                          fixed: "",
                          "min-width": "110"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "hotelName",
                          label: "考勤酒店",
                          align: "center",
                          fixed: "",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "hotelVid",
                          label: "酒店编码",
                          align: "center",
                          fixed: "",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "hotelCode",
                          label: "酒店代码",
                          align: "center",
                          fixed: "",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "员工",
                          align: "center",
                          fixed: "",
                          "min-width": "100"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.remark
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.userName))
                                      ])
                                    : _c(
                                        "el-popover",
                                        { attrs: { trigger: "hover" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "reference" },
                                              slot: "reference"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "vertical-align": "middle"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.userName)
                                                  )
                                                ]
                                              ),
                                              _c("lz-icon", {
                                                staticClass: "cell-tips__icon",
                                                attrs: {
                                                  type: "iconfont",
                                                  name: "lzicon-warning",
                                                  size: 14
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "max-width": "300px",
                                                "white-space": "normal"
                                              }
                                            },
                                            [_vm._v(_vm._s(scope.row.remark))]
                                          )
                                        ]
                                      )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4052218829
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userHotelName",
                          label: "员工所属酒店",
                          align: "center",
                          fixed: "",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "jobName",
                          label: "职位",
                          align: "center",
                          "min-width": "100"
                        }
                      }),
                      _vm._l(_vm.filterDays, function(index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            prop: "date_" + index,
                            label: _vm.formatHeaderDateColumn(index),
                            align: "center",
                            "min-width": "80"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _vm.checkDateColumnData(scope.row, index)
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getDateAttendanceDesc(
                                                  scope.row.id,
                                                  index
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "foreShiftCount",
                          label: "早班",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "middleShiftCount",
                          label: "中班",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "nightShiftCount",
                          label: "晚班",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "administrativeShiftCount",
                          label: "行政班",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "restCount",
                          label: "休息",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "lateCount",
                          label: "迟到",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "leaveEarlyCount",
                          label: "早退",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "absentCount",
                          label: "旷工",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "askOffCount",
                          label: "请假",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "oweDays",
                          label: "上月欠休",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "notWorkCount",
                          label: "缺勤数",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "workCount",
                          label: "出勤数",
                          align: "center",
                          "min-width": "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "提交人",
                          align: "center",
                          "min-width": "100"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("default_chars")(
                                          scope.row.operator
                                        )
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1292491104
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "提交时间",
                          align: "center",
                          "min-width": "135"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("default_chars")(
                                          scope.row.submitTime
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3265422106
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { ref: "listFoot", staticClass: "data-list__footer" },
              [
                _c("lz-pagination", {
                  attrs: {
                    total: _vm.pageData.total,
                    "page-count": _vm.pageData.pageCount,
                    "page-size": _vm.pageData.pageSize,
                    "current-page": _vm.pageData.currentPage
                  },
                  on: { "on-change": _vm.handlePaginationOnChange }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }